@import '_variables';
@import '~bootstrap/scss/bootstrap';
@import 'common';

/*** CV ***/

.raleway {
  font-family: 'Raleway Dots', cursive;
  font-size:1em;
}
.iceberg {
  font-family: 'Iceberg', cursive;
  font-size:2.95em;
  @include media-breakpoint-down(md) {
    padding-left:15px;
    font-size: 2em;
  }
  @include media-breakpoint-down(sm) {
    padding-left:15px;
    font-size: 1.7em;
  }
}

#wrapper {
  .header {
    min-height: 110px;
  }
}

h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
}

.aside {
  border-left: dotted 1px $light-grey;
  border-right: dotted 1px $light-grey;
}

.aside h3 {
  font-size: 14px;
}

.aside ul li {
  font-size: 13px;
}

.photo {
  height:100px;
  position: absolute;
  right:0;
}

.year {
  background: $grey;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #ffffff;
  padding: 14px 11px;
  font-size: small;
  position: absolute;
  left: 48%;
  top: -15px;
  z-index: 2;
}

.year-start {
  background: $primary;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  color: #ffffff;
  padding: 18px 13px;
  font-size: 1em;
  position: absolute;
  left: 47.3%;
  bottom: -30px;
  z-index: 2;
  font-weight: 600;
  @include media-breakpoint-down(md) {
    left:46.5%;
  }
  @include media-breakpoint-down(sm) {
    left:0;
  }
}

.year-end {
  background: $primary;
  border-radius: 50%;
  width: 62px;
  height: 62px;
  color: #ffffff;
  padding: 18px 13px;
  font-size: 1em;
  position: absolute;
  left: 47.3%;
  top: -10px;
  z-index: 2;
  font-weight: 600;
  @include media-breakpoint-down(md) {
    left:46.5%;
  }
  @include media-breakpoint-down(sm) {
    left:0;
  }
}

/* background: darkgoldenrod; */
.month-start {
  width: 60px;
  color: $grey;
  font-size: 10px;
  line-height: 1.2;
  text-align: center;
  padding: 2px;
  position: absolute;
  left: 47.5%;
  bottom: 33px;
  z-index: 2;
  border-top: solid $grey 1px;
  border-bottom: solid $grey 1px;
  background: #ffffff;
}

.month-end {
  width: 20px;
  height: 13px;
  color: $grey;
  font-size: 10px;
  line-height: 1.2;
  position: absolute;
  left: 50.5%;
  top: 28px;
  z-index: 2;
  border-top: solid $grey 1px;
  border-bottom: solid $grey 1px;
}

.position, .company {
  font-weight:700;
  font-size:1em;
  line-height:1.2;
  display:inline-block;
}
.address {
  color: $grey;
  line-height: 1.1;
}
.sector {
  text-decoration: underline;
  font-weight: 700;
}

.text-small {
  font-size: small;
}

.cv-left {
  @include media-breakpoint-up(sm) {
    padding: 10px 50px 10px;
  }
  @include media-breakpoint-down(sm) {
    border-left: solid $grey 1px;
    margin-left: 25px;
    padding: 0px 15px 15px 35px;
  }
}

.cv-right {
  @include media-breakpoint-up(md) {
    padding: 10px 50px 10px;
    border-right: solid $grey 2px;
  }
  @include media-breakpoint-down(sm) {
    border-left: solid $grey 1px;
    margin-left: 25px;
    padding: 15px 30px 0px 35px;
  }
}

.dashed {
  border-bottom: dotted lighten($secondary,40%) 0.5px;
}

.text-light-grey {
  color: $light-grey;
}

.secondary-infos {
}

.type-circle {
  height: 28px;
  width: 28px;
  background: $medium-grey;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  position:absolute;
  left:0;
  margin-right:5px;
  i {
    font-size: 1em;
    padding: 6px 4px;
    color:$secondary;
  }
  @include media-breakpoint-down(sm) {
    position: absolute;
    right:0px;
    left:auto;
    top:10px;
  }
}



#main-menu {
  background: #0c5460;
  z-index: 3;
  height: 100%;
}

#main-menu ul {
  padding: 10px;
}

#main-menu ul li {
  min-width: 30px;
  text-align: center;
  margin-bottom: 10px;
}

#main-menu ul li a {
  color: #FFFFFF;
  font-size: 25px;
  text-decoration: none;
}

#main-menu ul li a span {
  color: #FFFFFF;
  font-size: 8px;
  display: block;
}

#main-menu ul li a:hover {
  color: #47dedb;
}

#main-menu ul li a:hover span {
  color: #47dedb;
}

.computing-icon {
  position: absolute;
  left: -30px;
  top: -3px;
}

.computing-icon i {
  top: 4px;
}


hr.fancy-line {
  border: 0;
  height: 1px;
  position: relative;
  margin: 0.5em 0; /* Keep other elements away from pseudo elements*/
}
hr.fancy-line:before {
  top: -0.5em;
  height: 1em;
}
hr.fancy-line:after {
  content:'';
  height: 0.5em;   /* half the height of :before */
  top: 1px;        /* height of hr*/
}

hr.fancy-line:before, hr.fancy-line:after {
  content: '';
  position: absolute;
  width: 100%;
}

hr.fancy-line, hr.fancy-line:before {
  background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 75%);
  background: -webkit-gradient(radial, center center, 0px, center center, 75%, color-stop(0%,rgba(0,0,0,0.1)), color-stop(75%,rgba(0,0,0,0)));
  background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
  background: -o-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
  background: -ms-radial-gradient(center, ellipse cover, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
  background: radial-gradient(ellipse at center, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0) 75%);
}

body, hr.fancy-line:after {
  background: #f4f4f4;
}

.divider{
  color:#ccc;
  width:70%;
  margin:20px auto;
  text-align:center;
  line-height:1.2em;
}

.divider:before, .divider:after{
  content:"";
  vertical-align:top;
  display:inline-block;
  width:50%;
  height:0.65em;
  border-bottom:1px solid #ccc;
  margin:0 2% 0 -55%;
}
.divider:after{
  margin:0 -55% 0 2%;
}
