.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.w-40 {
  width: 40%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}


.duo-colors {
  --fa-primary-color: #05A6AA;
  --fa-secondary-color: #393b3b;
}

.pointer {
  cursor: pointer;
}

.icon-regular-size {
  font-size: 25px;
}

.item {
  border: solid #bdbdbd 1px;
  border-radius: 5px;
}

.text-small {
  font-size: x-small;
}

.text-xsmall {
  font-size: xx-small;
}

.text-error {
  color: #ad0000;
}

.text-note {
  font-size: x-small;
  color: #8f8f8f;
}

.text-barre {
  text-decoration: line-through;
}

.item-validated {
  color: #8f8f8f;
  background: #e6e6e6;
}

.text-done {
  color: #3a8c99;
}

.text-validated {
  color: #32a85c;
}

.text-pending {
  color: #e6e6e6;
}

.text-current {
  color: #d6457f;
}

.text-urgent {
  color: #ad0000;
}

.text-normal {
  color: #e8cf15;
}

.bg-done {
  background-color: #3a8c99;
}

.bg-validated {
  background-color: #32a85c;
}

.bg-current {
  background-color: #d6457f;
}

.bg-pending {
  background-color: #8f8f8f;
}

.bg-urgent {
  background-color: #ad0000;
}

.badge-project {
  background: #4b86a6;
  color: #ffffff;
}

.text-regular {
  font-size: 1em;
}

.btn-item {
  border-top: solid #dedede 1px;
  padding: 5px 0;
  text-align: center;
}

.form-control {
  border-radius: 0;
}


.text-note {
  font-size: smaller;
  color: #666666;
}

.to-categorize {
  background-color: #ffe100;
}

.break {
  word-break: break-all;
}

.logs {
  font-size: smaller;
}

.stylesheet-data {
  font-size: smaller;
}

.blue {
  color: #3A8AC1;
}

@media (min-width: 576px) {
  .d-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 576px) {
  .mr-sm-2, .mx-sm-2 {
    margin-right: .5rem !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
}
