//Variables

$primary: #005F6B;
$secondary: #343838;
$tertiary: #564660;

$grey: #767676;
$medium-grey: #cecece;
$light-grey: #ebebeb;

$color-base : #5bb6d1;
$color-dropdown : #b5d4f3;
$color-f : #679acc;
$color-ff : #6290be;
$color-c : #6fa2d5;
$color-cc : #a0c4e8;
$color-a025 : rgba(80, 180, 209, 0.25);
$color-a05 : rgba(80, 180, 209, 0.5);
$color-a06 : rgba(80, 180, 209, 0.6);
$color-a08 : rgba(80, 180, 209, 0.8);
$color-a025 : rgba(80, 180, 209, 0.25);
$color-a05 : rgba(91, 167, 209, 0.65);
$color-a06 : rgba(80, 180, 209, 0.6);
$color-a08 : rgba(80, 180, 209, 0.8);